import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { Box, Flex } from "reflexbox"
import { rgba } from "polished"

import Layout from "~/components/common/Layout"

import { Heading, Text } from "~/components/common/Typography"
import { Statistics, StatisticsRow } from "~/components/page/Statistics"
import HeroVideo from "~/components/page/HeroVideo"
import Carousel from "~/components/page/Carousel"
import { Slide } from "pure-react-carousel"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import useDimensions from "react-use-dimensions"
import Copyright from "~/components/common/Copyright"
import { mq } from "~/components/common/theme"
import BoxOverImage from "~/components/common/BoxOverImage"
import BackgroundImage from "gatsby-background-image"
import Source from "~/components/common/Source"
import ErrorBoundary from "~/components/common/ErrorBoundary"

const mqMobile = mq({
  display: ["block", "none"],
})
const mqNonMobile = mq({
  display: ["none", "block"],
})

const Page = ({ myPage = 1, mySubpage = 0 }) => {
  const data = useStaticQuery(graphql`
      query {
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video2_16x9_CTM_3bit" }
          ) {
              name
              publicURL
          }
          icon1: file(name: { eq: "2.1a-LoveAboveAll" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          icon2: file(name: { eq: "2.1b-Comraderie" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          icon3: file(name: { eq: "02.1c-Love" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          slider1: file(name: { eq: "2.1-Slider1" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          slider2: file(name: { eq: "2.1-Slider2" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          slider3: file(name: { eq: "2.1-Slider3" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          slider4: file(name: { eq: "2.1-Slider4" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          slider5: file(name: { eq: "2.1-Slider5" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          slider6: file(name: { eq: "2.1-Slider6" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          slider7: file(name: { eq: "2.1-Slider7" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote1: file(name: { eq: "2.1-RelationshipsMatter-1-imageonly" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote2: file(name: { eq: "2.1-RelationshipsMatter-2-imageonly" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote3: file(name: { eq: "2.1-RelationshipsMatter-3-imageonly" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote4: file(name: { eq: "2.1-RelationshipsMatter-4-imageonly" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const [ref, { x, height = 620, width }] = useDimensions()

  const {
    video,
    quote1,
    quote2,
    quote3,
    quote4,
    icon1,
    icon2,
    icon3,
    slider1,
    slider2,
    slider3,
    slider4,
    slider5,
    slider6,
    slider7,
  } = data

  const slider = [
    {
      img: slider1,
      text:
        "Coach Jane is a soccer coach in Chicago working with a young player named Erica.",
    },
    {
      img: slider2,
      text:
        "Erica is a great athlete but has a hard time dealing with disappointments in sports.",
    },
    {
      img: slider3,
      text:
        "When she made a great pass, and her teammate failed to score a goal, she got frustrated. When it happened a second time, she sulked off the field and was upset for the rest of practice.",
    },
    {
      ref: ref,
      img: slider4,
      text:
        "Many of her previous coaches had tried and failed to get Erica to overcome these setbacks on the field. But Coach Jane figured out that she could use this setback to teach Erica about good sportsmanship. She talked to her about recognizing other people’s efforts rather than just their failures.",
    },
    {
      img: slider5,
      text:
        "At a midseason tournament, Erica passed the ball, and her teammate missed the shot. When she got upset, Erica looked at Coach Jane, who applauded Erica and her teammate and encouraged them to try again.",
    },
    {
      img: slider6,
      text:
        "Erica looked back at the player she passed to and did the same thing: smiled and cheered her on!",
    },
    {
      img: slider7,
      text:
        "After the game, Coach Jane talked to Erica and praised her for having a good attitude even when things didn’t go perfectly.",
    },
  ]

  console.log("Slide height, ", height)

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="One of the main reasons girls decide to stay in sports is because of the relationships they build with each other and with you, their coach."
        videoSrc={video.publicURL}
        videoLabel={video.name}
      />

      <Infobox maxWidth={"desktop"}>
        <Heading
          fontSize={["24px", "32px", "48px"]}
          lineHeight={["33px", "42px", "60px"]}
          as={"h2"}
          maxWidth={"desktop"}
          mx={"auto"}
        >
          Relationships are integral to the growth of girls in both sports and
          life.
        </Heading>
        <Box maxWidth={"blockText"} mx={"auto"}>
          <Text fontFamily={"AdineuePROBoldWeb"} color={"black"} uppercase>
            You can help facilitate relationships among your athletes, while
            also developing appropriate, supportive, and meaningful
            relationships with athletes. Positive team dynamics are about
            camaraderie and feeling a sense of acceptance and belonging. When
            girls feel less than accepted or supported, they quit. Conversely,
            when girls feel accepted and valued, they try hard and perform well.
            Positive coaching is about creating a welcoming, supportive, and
            inspiring environment.<Source>14</Source>
          </Text>
          <Text fontFamily={"AdineuePROBoldWeb"} color={"black"} uppercase>
            In this section, you’ll learn how to help girls intentionally create
            connections within your teams by implementing research-based
            tactics. But first, let’s dig deeper into why relationships are so
            important.
          </Text>
        </Box>
      </Infobox>

      <Statistics background="white">
        <InfoboxDetailed pt={0} pb={2}>
          <Heading
            fontSize={["24px", "32px", "48px"]}
            lineHeight={["33px", "42px", "60px"]}
            background={"yellow"}
            fontFamily="AdineuePROBlackWeb"
            px={3}
            as={"h2"}
          >
            Research has shown that relationships are the secret to happiness
          </Heading>
          <Text>
            A Harvard study followed a cohort of 268 Harvard students for almost
            80 years.<Source>15</Source> During this time, the researchers
            looked at various aspects of the participants’ lives, where they
            lived, where they worked, and how much money they made to find out
            the science behind what made for a happy life. In an interview near
            the project’s conclusion, the leading researcher, George Valliant,
            highlighted some of the results in his study:
          </Text>
        </InfoboxDetailed>

        <Box maxWidth={"950px"} mx={"auto"}>
          <StatisticsRow
            image={icon1}
            headingText={`“The only things that really matter in life are your relationships to other people.”`}
          />
          <StatisticsRow
            reverse
            image={icon2}
            headingText={`“Our relationships with other people matter, and matter more than anything else in the world.”`}
          />
          <StatisticsRow
            image={icon3}
            headingText={`“This study has come to the conclusion that there is one thing that really matters: love.”`}
          />
        </Box>
        <Heading
          as={"h2"}
          maxWidth={"mediumContent"}
          fontSize={["24px", "32px", "48px"]}
          lineHeight={["33px", "42px", "60px"]}
          fontFamily={"AdineuePROBoldWeb"}
        >
          Notice a pattern? So what does the research suggest about working with
          girls?
        </Heading>
      </Statistics>

      <InfoboxDetailed
        headerText={"Relationships matter"}
        contentMaxWidth={"desktop"}
        pt={[6, 7]}
        pb={6}
      >
        <Box
          css={css`
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
          `}
        >
          <Box
            mt={6}
            css={css`
              position: relative;
            `}
          >
            <BackgroundImage
              Tag="div"
              loading={"eager"}
              fluid={quote1.childImageSharp.fluid}
              backgroundColor={`#333333`}
              css={p => [
                css`
                  /* Sizing */
                  width: 100%;
                  height: fit-content;

                  /* Background styles */
                  background-size: cover;
                  background-position: 60% center;
                  background-repeat: no-repeat;
                  background-attachment: scroll;
                  //overflow: hidden;
                `,
                mqMobile,
              ]}
            >
              <Box width={"90%"} mx={"auto"} py={6}>
                <Text
                  doBreak
                  fontFamily={"PermanentMarkerRegular"}
                  fontSize={["18px", "18px", "40px"]}
                  lineHeight={["27px", "27px", "58px"]}
                  color={"yellow"}
                >
                  Relationships can move someone in a different direction, for
                  the better or for the worse.
                </Text>
              </Box>
            </BackgroundImage>

            <BoxOverImage width={90} css={mqNonMobile}>
              <Text
                doBreak
                fontFamily={"PermanentMarkerRegular"}
                fontSize={["18px", "18px", "40px"]}
                lineHeight={["27px", "27px", "58px"]}
                color={"yellow"}
              >
                Relationships can move someone in a different direction, for the
                better or for the worse.
              </Text>
            </BoxOverImage>
            <Img
              css={mqNonMobile}
              fluid={quote1.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>
          <Flex
            flexDirection={["column", "row"]}
            alignItems="center"
            justifyItems={"center"}
          >
            <Box
              width={["100%"]}
              mr={[0, 4]}
              mt={5}
              css={css`
                position: relative;
              `}
            >
              <BackgroundImage
                Tag="div"
                loading={"eager"}
                fluid={quote2.childImageSharp.fluid}
                backgroundColor={`#333333`}
                css={p => [
                  css`
                    /* Sizing */
                    width: 100%;
                    height: fit-content;

                    /* Background styles */
                    background-size: cover;
                    background-position: 60% center;
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    //overflow: hidden;
                  `,
                  mqMobile,
                ]}
              >
                <Box width={"90%"} mx={"auto"} py={6}>
                  <Text
                    doBreak
                    fontFamily={"PermanentMarkerRegular"}
                    fontSize={["18px", "18px", "40px"]}
                    lineHeight={["27px", "27px", "58px"]}
                    color={"yellow"}
                  >
                    Coach relationships can be the most important influence on a
                    girl’s life, the person who tips the scale in a positive
                    direction.
                  </Text>
                </Box>
              </BackgroundImage>

              <BoxOverImage width={90} css={mqNonMobile}>
                <Text
                  doBreak
                  fontFamily={"PermanentMarkerRegular"}
                  fontSize={["18px", "18px", "40px"]}
                  lineHeight={["27px", "27px", "58px"]}
                  color={"yellow"}
                >
                  Coach relationships can be the most important influence on a
                  girl’s life, the person who tips the scale in a positive
                  direction.
                </Text>
              </BoxOverImage>
              <Img
                css={mqNonMobile}
                fluid={quote2.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            </Box>
            <Box
              width={["100%"]}
              ml={[0, 4]}
              mt={5}
              css={css`
                position: relative;
              `}
            >
              <BackgroundImage
                Tag="div"
                loading={"eager"}
                fluid={quote3.childImageSharp.fluid}
                backgroundColor={`#333333`}
                css={p => [
                  css`
                    /* Sizing */
                    width: 100%;
                    height: fit-content;

                    /* Background styles */
                    background-size: cover;
                    background-position: 60% center;
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    //overflow: hidden;
                  `,
                  mqMobile,
                ]}
              >
                <Box width={"90%"} mx={"auto"} py={6}>
                  <Text
                    doBreak
                    fontFamily={"PermanentMarkerRegular"}
                    fontSize={["18px", "18px", "40px"]}
                    lineHeight={["27px", "27px", "58px"]}
                    color={"yellow"}
                  >
                    Every interaction you as a coach have with a girl counts:
                    every moment is an opportunity to build and cultivate
                    relationships.
                  </Text>
                </Box>
              </BackgroundImage>

              <BoxOverImage width={90} css={mqNonMobile}>
                <Text
                  doBreak
                  fontFamily={"PermanentMarkerRegular"}
                  fontSize={["18px", "18px", "40px"]}
                  lineHeight={["27px", "27px", "58px"]}
                  color={"yellow"}
                >
                  Every interaction you as a coach have with a girl counts:
                  every moment is an opportunity to build and cultivate
                  relationships.
                </Text>
              </BoxOverImage>
              <Img
                css={mqNonMobile}
                fluid={quote3.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            </Box>
          </Flex>
          <Box
            mt={5}
            css={css`
              position: relative;
            `}
          >
            <BackgroundImage
              Tag="div"
              loading={"eager"}
              fluid={quote4.childImageSharp.fluid}
              backgroundColor={`#333333`}
              css={p => [
                css`
                  /* Sizing */
                  width: 100%;
                  height: fit-content;

                  /* Background styles */
                  background-size: cover;
                  background-position: 60% center;
                  background-repeat: no-repeat;
                  background-attachment: scroll;
                  //overflow: hidden;
                `,
                mqMobile,
              ]}
            >
              <Box width={"90%"} mx={"auto"} py={6}>
                <Text
                  doBreak
                  fontFamily={"PermanentMarkerRegular"}
                  fontSize={["18px", "18px", "40px"]}
                  lineHeight={["27px", "27px", "58px"]}
                  color={"yellow"}
                >
                  The effectiveness of your relationship as a coach of girls
                  will depend in part on your ability to relate to them. It is
                  important that all the girls on your team feel like they have
                  a personal connection with you.
                </Text>
              </Box>
            </BackgroundImage>

            <BoxOverImage width={90} css={mqNonMobile}>
              <Text
                doBreak
                fontFamily={"PermanentMarkerRegular"}
                fontSize={["18px", "18px", "40px"]}
                lineHeight={["27px", "27px", "58px"]}
                color={"yellow"}
              >
                The effectiveness of your relationship as a coach of girls will
                depend in part on your ability to relate to them. It is
                important that all the girls on your team feel like they have a
                personal connection with you.
              </Text>
            </BoxOverImage>
            <Img
              css={mqNonMobile}
              fluid={quote4.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>
        </Box>
      </InfoboxDetailed>

      <ErrorBoundary>
        <Box
          as="section"
          flexDirection="column"
          justifyContent="start"
          pt="6"
          px="6"
          pb="6"
          bg="seafoam"
        >
          <Box textAlign="center">
            <Heading
              fontSize={["24px", "32px", "48px"]}
              lineHeight={["33px", "42px", "60px"]}
              mb="5"
            >
              So what can these relationships look like?
            </Heading>
          </Box>
          <Box
            maxWidth="desktop"
            mx="auto"
            bg={rgba("white", 0.8)}
            mt="5"
            px={[5, 5, 6]}
            py={[6]}
          >
            <Box mx="auto" bg="white" px={[3, 5]} py={[4, 5]}>
              <Carousel>
                {slider.map((slide, index) => (
                  <Slide
                    key={index}
                    index={index}
                    style={{ height: `${height ? height : 620}px` }}
                  >
                    <Flex
                      flexDirection={"column"}
                      alignItems={"center"}
                      width={["80%", "80%", "70%"]}
                      mt={[4, 5]}
                      mx={"auto"}
                      ref={slide.ref}
                    >
                      <Box width={["100%"]}>
                        <Img
                          fluid={slide.img.childImageSharp.fluid}
                          loading={"eager"}
                          css={css`
                            height: 100%;
                            width: 100%;
                          `}
                        />
                      </Box>
                      <Box width={["100%"]}>
                        <Text
                          textAlign={"center"}
                          px={6}
                          fontSize={["16px", "16px", "18px"]}
                          lineHeight={["24px", "24px", "36px"]}
                        >
                          {slide.text}
                        </Text>
                      </Box>
                    </Flex>
                  </Slide>
                ))}
              </Carousel>
            </Box>
          </Box>
        </Box>
      </ErrorBoundary>

      <InfoboxDetailed
        as={"section"}
        headerText={"What made these magic words/actions work?"}
        pt={6}
        pb={5}
      >
        <Box maxWidth={"mediumContent"} mx={"auto"}>
          <Text>
            Well, it wasn’t just the words that changed Erica’s behavior. Coach
            Jane invested in building a relationship with Erica: she talked to
            her about life and school and got to know her during practice and at
            games. Through all these times and situations, Coach Jane got to
            know what motivates Erica. So it was the relationship, built over
            time, and not the words, that inspired the change.
          </Text>
        </Box>
      </InfoboxDetailed>
      <Copyright mt={6}/>
    </Layout>
  )
}

export default withProgress(Page, 1, 0)
