import React from "react"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Box, Flex } from "reflexbox"
import { Heading, Text } from "~/components/common/Typography"

const StatisticsRow = ({
  reverse,
  linkText,
  link,
  headingText,
  image,
  ...props
}) => {
  return (
    <Flex
      mt="6"
      mb={6}
      flexDirection={["column", reverse ? "row-reverse" : "row"]}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Flex
        width={["100%", "50%"]}
        alignItems="center"
        justifyContent={"center"}
        // justifyContent={
        //   reverse ? ["center", "flex-end"] : ["center", "flex-start"]
        // }
        px={4}
      >
        {image ? (
          <Box width={"100%"} maxWidth={["40%", "70%"]}>
            <Img
              fluid={image.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            />
          </Box>
        ) : (
          <Flex
            width={`${(4 / 6) * 100}%`}
            mx="auto"
            justifyContent="center"
            alignItems="center"
            css={css`
              border-radius: 50%;
              width: 350px;
              height: 350px;
              opacity: 0.48;
              background-color: #ffffff;
            `}
          >
            Graphic
          </Flex>
        )}
      </Flex>
      <Box width={["100%", "50%"]} py="6" textAlign={["center", "left"]}>
        <Text
          uppercase
          my={0}
          color={"black"}
          fontFamily="AdineuePROBoldWeb"
          fontSize={["20px", "20px", "32px"]}
          lineHeight={["24px", "24px", "37px"]}
        >
          {headingText}
        </Text>
        {linkText && (
          <Text
            mt="3"
            fontSize={["14px", "16px", "20px"]}
            lineHeight={["16px", "24px", "30px"]}
          >
            (Source:{" "}
            <a
              href={link ? link : "#"}
              target={"_blank"}
              css={p => css`
                color: ${p.colors.seafoam};
                font-family: "AdineuePROBoldWeb";
              `}
            >
              {linkText}
            </a>
            )
          </Text>
        )}
      </Box>
    </Flex>
  )
}

const Statistics = ({
  background,
  headerText,
  children,
  header,
  headerProps,
  ...props
}) => {
  return (
    <Box bg={background ? background : "white"} as="section">
      <Box
        flexDirection="column"
        justifyContent="start"
        textAlign="center"
        maxWidth={["100%", "tablet", "desktop"]}
        mx="auto"
        px="5"
        {...props}
      >
        <Box mx="auto" mb="3" px="5" display="inline-block">
          {header ? (
            header
          ) : (
            <Heading
              fontSize={["24px", "24px", "54px"]}
              lineHeight={["32px", "36px", "66px"]}
              fontFamily="AdineuePROBlackWeb"
              maxWidth="desktop"
              {...headerProps}
            >
              {headerText}
            </Heading>
          )}
        </Box>
        {children}
      </Box>
    </Box>
  )
}

export { Statistics, StatisticsRow }
